import React from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Tooltip from '../common/Tooltip';
import PhoneIcon from '@mui/icons-material/Phone';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';

const PhoneAgent = () => (
  <Tooltip tooltip="This is a phone based agent, you will start the agent, then call it on a phone number">
    <PhoneIcon/>
  </Tooltip>
)
const SpeakerPhoneAgent = () => (
  <Tooltip tooltip="This is a WebRTC media agent, you will talk to it directly from this browser" sx={{ zIndex: 9999 }}>
    <SpeakerPhoneIcon />
  </Tooltip>
)


export default function SelectAgent({ agents, options, modelName, setModelName, setFunctionSupport, setAudioModel, setVoices, tooltip, ...rest }) {
  let [selected, setSelected] = React.useState(false);

  const handleOptionChange = (value) => {
    setModelName(value);
    setAudioModel(options[value].audioModel);
    setFunctionSupport(options[value].supportsFunctions);
    setVoices(agents[value].voices || {});
    setSelected(true);
  };

  console.log({ selected, modelName }, 'agebt render');
  return (
    <Tooltip {...{ tooltip }} open={!!tooltip}>
      <Select
        value={modelName}
        startDecorator={selected && modelName && (options[modelName].audioModel ? <SpeakerPhoneAgent /> : <PhoneAgent />)}
        onChange={(e, value) => handleOptionChange(value)} sx={{ mb: 2 }} {...{ ...rest }}>
        {Object.entries(options).map(([key, value]) => (
          <Option
            key={key}
            value={key}>
            <ListItemDecorator>
              {value.audioModel ? <SpeakerPhoneAgent /> : <PhoneAgent />}
            </ListItemDecorator>
            {value.description}&nbsp;
            {!value.supportsFunctions && '(no function calling)'}</Option>
        ))}
      </Select>
    </Tooltip>
  );

}