import { useState, useEffect } from 'react';
import Select from '@mui/joy/Select';
import Grid from '@mui/joy/Grid';
import Option from '@mui/joy/Option';
import LanguageIcon from '@mui/icons-material/Language';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Tooltip from '../common/Tooltip';
import SelectGroupedOptions from '../common/GroupSelect';

const buildVoiceOptions = (l, voices) => Object.fromEntries(
  Object.entries(voices).map(
    ([provider, languages]) =>
    ([provider,
      languages[l]?.map(voice => ({ ...voice, description: `${voice.description} (${voice.gender})` }))
    ])
  )
);

const haveVoice = (voiceOptions, voice) => voiceOptions && voice && Object.entries(voiceOptions).some(a => Array.isArray(a) && a.some(v => v?.name === voice));

export default function SelectVoice({
  disabled,
  language,
  setLanguage,
  defaultLanguage,
  voice,
  setVoice,
  voices,
  tooltip
}) {
  let [languages, setLanguages] = useState();
  let [voiceOptions, setVoiceOptions] = useState();

  
  
  useEffect(() => {
    console.log({ voices, languages, language });
    if (language && voices) {
      let options = buildVoiceOptions(language, voices);
      if (Object.keys(options).length) {
        setVoiceOptions(options);
      }
      else {
        setLanguage(null);
      }
      console.log({ voices, languages, language, options }, 'SelectVoice');
    }
    setLanguages(Object.keys(Object.values(voices).reduce((o, v) => ({ ...o, ...v }), {})));
  }, [language, voices]);

  useEffect(() => {
    if (languages?.length && !language) {
      setLanguage(languages.find(l => l === defaultLanguage) || languages[0]);
    }
  }, [languages, language]);

  useEffect(() => {
    console.log({ voiceOptions, voice, haveVoice: haveVoice(voiceOptions, voice) }, 'voiceoptions');
    if (voiceOptions && Object.keys(voiceOptions).length && (!voice || !haveVoice(voiceOptions, voice))) {

      let [provider, pVoices] = Object.entries(voiceOptions)?.[0] || []
      let def = pVoices?.find(l => l === defaultLanguage)?.name;
      let first = pVoices?.[0]?.name;
      let voiceString = provider && (def || first) && `${provider}:${def || first}`
      console.log({ provider, def, first, voiceString}, 'setting voice');
      setVoice(voiceString);
 
    }
  }, [voice, voiceOptions]);

  return (

    <Grid container sx={{ width: '100%' }}>
      <Grid xs={12} sm={4}>
        <Select
          value={language}
          disabled={disabled}
          placeholder="select language"
          label="language"
          startDecorator={<LanguageIcon />}
          onChange={(e, value) => setLanguage(value)} sx={{ m: 2 }}>
          {languages?.length && languages.map(option => (
            <Option key={option} value={option}>{`${option}`}</Option>
          ))}
        </Select>
      </Grid>
      <Grid xs={12} sm={8}>
        <Tooltip {...{ tooltip }} open={!!tooltip}>
          <SelectGroupedOptions
            value={voice}
            disabled={disabled}
            placeholder="select voice"
            startDecorator={<RecordVoiceOverIcon />}
            onChange={(e, value) => setVoice(value)}
            sx={{ m: 2 }}
            options={voiceOptions}
          >
          </SelectGroupedOptions>
        </Tooltip>
      </Grid>
    </Grid>

  );

}